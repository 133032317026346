
.App {
  text-align: center;
}
html, body{
  padding: 0%;
  margin: 0%;
  /*font-family: Arial, Helvetica, sans-serif;*/
  height: 100%;
  background-color: #1e2124;
}

body{
  height: 100%;
  background-color: #1e2124;
  position: relative;
  display: flex;
  flex-direction: column;
  /*min-height: 100vh;*/
}

main {
  width: 100%;
  display: flex;
  overflow-y: auto;
  justify-content: center;
  position: relative;
  background-color: #282b30;
  /*min-height: 100vh;*/
}
.main_centre{
  width: 80%;
  height: 100%;
  background-color: #424549;
  min-height: calc(100vh - 80px);
  /*min-height: 100vh;*/
}

.start_vleap {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 60px;
}

.sub_banner_segment_left{
  float: left;
  width: 45%;
  height: 3px;
  background-color: white;
}

.sub_banner_segment_right{
  float: right;
  width: 45%;
  height: 3px;
  background-color: white;
}

.sub_banner {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 60px;
}

.sub_banner_title{
  color: white;
  text-align: center;
  font-size: x-large;
}

.sub_banner_icon{
  padding: 0px 20px;
}

.greetings_row {
  display: flex;

  align-items: start;
  justify-content: start;
  width: auto;
  height: auto;
}

.greetings_col {
  display: grid;
  place-items: start;

}

.greetings_img {
  align-self: end;
  padding-left: 100px;
  width: min(300px, 50%);
  height: 400px;
}
.greetings{
  color: white;
  padding-left: 200px;
  font-size: xx-large;
}

.sub_greetings{
  color: white;
  padding-left: 200px;
  font-size: large;
}

.content_grid {
  display: grid;
  padding: 0 5%;
  padding-bottom: 50px;
  box-sizing: border-box;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(min(450px, 100%), 1fr));
  gap: 100px; /* Adjust the gap as needed */
}

.content_box {
  background-color: #1e2124;
  /*width: 500px;*/
  height: 650px;
  padding: 0px;
  text-align: center;
}

.content_box .banner{
  /*background-color: #1e2124;*/
  background-color: #36393e;
  width: 100%;
  height: 50%;

}

.content_box .title{
  text-align: center;
  color: white;
  font-size: x-large;
}

.content_box .text{
  text-align: left;
  padding: 0% 10%;
  color: white;
  font-size: medium;
}
.content_box_link {
  padding: 0 0;
  margin: 0;
}





@media (max-width: 1309px) {/*1309px*/

  .greetings_img {
    display: none;
    align-self: end;
    padding-left: 100px;
    width: min(300px, 50%);
    height: 400px;
  }

  .content_box .banner{
    background-color: #424549;
    width: 100%;
    height: 50%;
  }

  .main_centre{
    background-color: #1e2124;
  }

  .greetings_img {
    padding-left: 0px;

  }
  .start_vleap {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 20px;
  }
  main {
    width: 100%;
    display: flex;
    overflow-y: auto;
    justify-content: center;
    position: relative;
    background-color: #1e2124;
  }
  .main_centre{
    width:100%;
    height: auto;
    background-color: #1e2124;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .greetings{
    color: white;
    padding-left: 20px;
    font-size: xx-large;
  }

  .sub_greetings{
    color: white;
    padding-left: 20px;
    font-size: large;
  }

  .sub_banner{
    padding-top: 10px;
  }

  .content_grid {
    display: grid;
    padding: 0 0%;

    box-sizing: border-box;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(min(450px, 100%), 1fr));
    gap: 0px; /* Adjust the gap as needed */
  }

}









a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 25px;
}

.createPostPage {
  width: 100%;
  height: calc(100vh - 80px);
  display: grid;
  place-items: center;
}

.cpContainer {
  width: 500px;
  height: auto;
  padding: 20px;
  background-color: black;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
}

.cpContainer h1 {
  text-align: center;
}

.cpContainer label {
  font-size: 25px;
}
.cpContainer .inputGp {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.inputGp input,
.inputGp textarea {
  font-size: 18px;
  border: none;
  border-radius: 2px;
  padding: 5px;
}

.inputGp input {
  height: 40px;
}
.inputGp textarea {
  height: 150px;
}

.cpContainer button {
  margin-top: 20px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}
input,
textarea {
  margin-top: 5px;
}

.loginPage {
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginPage p {
  font-size: 30px;
}

/* GOOGLE BUTTOn */
.login-with-google-btn {
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 5px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 25px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.login-with-google-btn:active {
  background-color: #eeeeee;
}
.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
  0 0 0 3px #c8dafc;
}
.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.homePage {
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background-color: rgb(40,43,48);
}

.homePage .post {
  width: 600px;
  height: auto;
  max-height: 600px;
  background-color: rgb(40,43,48);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.post .postHeader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.postHeader .title {
  flex: 50%;
}
.postHeader .deletePost {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.deletePost button {
  border: none;

  background: none;
  font-size: 30px;
  cursor: pointer;
}

.post .postTextContainer {
  word-wrap: break-word;
  height: auto;
  max-height: 400px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}
