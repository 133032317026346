
nav {
    /*flex: 1 1 auto;*/
    /*height: 80px;*/
    /*background-color: #36393e;*/
    /*border-bottom: 1px solid #555;*/

    display: none;
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*height: 80px;*/
    /*background-color: #222;*/
    /*flex-direction: column;*/
    /*align-items: center;*/


}
.logo {
    display: none;
    /*width: auto;*/
    /*height: 100%;*/
    /*color: #EEEEEE;*/
    /*float: left;*/
    /*padding: 0px 100px;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/

    /*font-family: SansSerif, serif;*/
    /*font-size: xx-large;*/


}
.logo .text {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.list-item {
    list-style-type: none;
    float: right;
    margin-right: 25px;
    margin-top: 0px;
}
.list-item li {
    float: left;
    padding: 0px 0px;
    font-size: 18px;
    /*border-radius: 3px;*/
    transition: all .7s ease-in-out;
    display: grid;
    place-items: center;

}

.list-item .icon_txt{
    padding-top: 5px;
    width: auto;
    height: auto;
    text-align: center;
    color: white;
    font-size: small;
}
.list-item li a {
    color: #EEE;
}

.bar_link{
    margin-left: 0;
    margin-right: 0;
    float: left;
    padding: 0px 13px;
    font-size: 18px;
    /*border-radius: 3px;*/
    transition: all .7s ease-in-out;
    display: grid;
    place-items: center;
    /*padding: 0px 0px;*/
    /*width: 100%;*/
    /*color: #EEEEEE;*/
    /*text-align: center;*/
    /*vertical-align: middle;*/
}

@media (max-width: 1309px) {/*1309px*/
    .logo {
        display: none;
        /*width: 100%;*/
        /*height: 80px;*/
        /*background-color: #333;*/
        /*border-bottom: 1px solid #444;*/
    }
    nav {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 80px;
        background-color: #222;
        flex-direction: column;
        align-items: center;
    }

}