
.nav_top {
    /*flex: 1 1 auto;*/
    /*height: 80px;*/
    /*background-color: #36393e;*/
    /*border-bottom: 1px solid #555;*/

    display: flex;
    height: 80px;
    background-color: #222;
    /*flex-direction: column;*/
    /*align-items: center;*/


}
.logo_top {
    width: auto;
    height: 100%;
    color: #EEEEEE;
    float: left;
    padding: 0px 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: SansSerif, serif;
    font-size: xx-large;


}
.logo_top .text_top {
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.list-item_top {
    list-style-type: none;
/*align-self: flex-end;*/

    float: right;
    margin-left: auto;
    margin-top: 10px;
    /*margin-right: 25px;*/
    /*margin-top: 17px;*/
}
.list-item_top li {

    float: left;
    padding: 0px 25px;
    font-size: 18px;
    /*border-radius: 3px;*/
    transition: all .7s ease-in-out;
    display: grid;
    place-items: center;
}
.bar_link{
    float: left;
    padding: 0px 13px;
    font-size: 18px;
    /*border-radius: 3px;*/
    transition: all .7s ease-in-out;
    display: grid;
    place-items: center;
    /*padding: 0px 0px;*/
    /*width: 100%;*/
    /*color: #EEEEEE;*/
    /*text-align: center;*/
    /*vertical-align: middle;*/
}

.list-item_top .icon_txt_top{
    /*padding-top: 5px;*/
    width: auto;
    height: auto;
    padding-bottom: 50px;
    text-align: center;
    color: white;
    font-size: small;
    /*display: inline-block;*/
    /*margin-left: 10px*/
}
.list-item_top li a {
    padding: 0 0;
    color: #EEE;
    vertical-align: middle;
    text-decoration: none;

}

@media (max-width: 1309px) {/*1309px*/
    .logo_top {
        display: none;
        /*width: 100%;*/
        /*height: 80px;*/
        /*background-color: #333;*/
        /*border-bottom: 1px solid #444;*/
    }
    .nav_top {
        display: none;
        /*display: flex;*/
        /*position: fixed;*/
        /*bottom: 0;*/
        /*left: 0;*/
        /*right: 0;*/
        /*height: 80px;*/
        /*background-color: #222;*/
        /*flex-direction: column;*/
        /*align-items: center;*/
    }

}