.footer_div {
    display: flex;
    width: 100%;
    height: 70px;
    background-color: #222;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.footer_contact{
    float: left;
    color: #EEEEEE;
    text-align: center;
    width: 50%;
    font-size: small;
}

.footer_copyright{
    float: left;
    color: #EEEEEE;
    width: 50%;
    font-size: small;
}

@media (max-width: 1309px) {
    .footer_div{
        margin-bottom: 80px;
    }
}